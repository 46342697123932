@import "mixins";
@import "material-colors/colors.module";

@import "material-colors/tokens/tokens_v1.css";
//@import "material-colors/tokens/tokens_v2.css";
//@import "material-colors/tokens/tokens_4A7D14.css";
//@import "material-colors/tokens/tokens_559116.css";
//@import "material-colors/tokens/tokens_64982D.css";

@import "material-colors/typography.module.css";

//@import "material-colors/theme.dark.css" (prefers-color-scheme: dark);
//@import "material-colors/theme.light.css" (prefers-color-scheme: light);
@import "material-colors/theme.light.css";

@mixin white-text($border: true) {
  @if $border == true {
    border-style: inset;
    border-color: $primary;
  }
  //background: $secondary;
  background: $tertiary-container;
  //background: $secondary-container;
  color: $on-tertiary-container;
  @include rounded-corners();
}

@mixin dark-border($width, $addendum: "", $color: $on-primary-container) {
  //border#{$addendum}: #{$width}px solid $color !important;
  border#{$addendum}: #{$width} solid $color !important;
}

$grey-0: #959595;
$grey-1: #d7d7d7;
$grey-2: #bbbbbb;
$grey-3: #7d7d7d;
$grey-4: #5f5f5f;
