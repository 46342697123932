
.loader {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.loader:after {
    animation: loader 1.2s linear infinite;
    border-color: #000 transparent #000 transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    content: " ";
    display: block;
    height: 30px;
    margin: 1px;
    width: 30px;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}