@import "mixins";
@import "color-scheme";

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.customSpotifyContainer #album_tracks{
  margin-top: .2rem;
  border-left: 1px solid black;
  overflow-y: scroll;
  background: $secondary-container;
  max-height: 15.2rem;
  height: fit-content;
  @media (max-width: $lg) {
    max-height: 20rem;
  }

  @media (max-width: $md) {
    max-height: 12rem;
  }

  &.col, &.col-6 {
    padding-left: 0;
    padding-right: 0;
  }

  .list .item {
    font-size: 1rem;
    border-radius: 0;
    padding: .25rem .75rem;
    border-right: none;
    border-left: none;
    background: $secondary-container;
  }
  .list .item:first-child{
     border-radius: 0;
     border-top: none;
   }
  .list .item:last-child{
    border-bottom: none;
    border-radius: 0;
  }
}
.topRowFullSize {
  @media (max-width: $xs) {
    justify-content: space-between;
  }
}

input#range, progress {
  width: inherit !important;
}
.progressBarBox {
  padding: .2em;
  display: flex;
  margin: 0;
  @media only screen and (max-width: $md) {
    display: block;
    padding: 0!important;
  }
}
#player_album_image {
  object-fit: cover;
  border-radius: 0;
}
.customSpotifyContainer  {
  width: 100%;
  font-size: 1.5rem;
  line-height: 2.5rem;

  @media only screen and (max-width: $md) {
    font-size: 1.2em !important;
  }
}

#bottom_reddit_title {
  clear: both;
}

#bottom_reddit_link {
  margin-top: .5rem;
  clear: both;
  float: revert;
}
@media only screen and (min-width: $sm) {

  #clearQueueButton {
    float: right;
    border-top-right-radius: 0;
  }
}

.no_padding {
  padding: 0 !important;
}
.no_margin {
  margin: 0 !important;
}

.inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.display-none {
  display: none !important;
}

.queue_site_icon {
  margin-right: 1em;
}
.track_row {
  display: flex;
  align-items: flex-start;
}
div.song_info_wrapper{
  padding-left: 1.5em;
  width: fit-content;
  align-self: stretch;
}

#toggle_show_media_button {
  left: 0;
}

#song_info {
  display: inline-block;
  padding: 0 1.3rem;
  max-width: fit-content;
  height: fit-content;
}

.centerDiv {
  margin: auto !important;
  width: 100% !important;
}

#queue_wrapper .nav {
  flex-wrap: nowrap;
}


.btn.controlButton + .btn.controlButton {
  margin-left: 1em;
}

.btn.controlButton {
  padding: .25em .5em;
}

ul.queue_list,
.queue_list.list {
  border-radius: 1px !important;
  border: none;
  max-height: 25vh;
  min-width: 15rem;
  overflow: auto;
  @media only screen and (max-width: $md) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .item {
    border: none;
  }
}
#queue_up_next {
  margin-top: .5rem;
}
#queue_up_next .card-body{
  padding: 0;
}
#queue_up_next .card-header{
  justify-content: flex-start !important;
  align-items: center !important;
  border-bottom: 1px solid black
}

.streaming_icon {
  padding-bottom: .2em;
}

.control_buttons_background {
  width: fit-content;
  @include popout(.5em);
  margin: 0 auto .5em;

  //background: $primary-container;
  //background: $tertiary-container;
  background: $secondary-container;
  border: 1px solid $on-primary-container;
  @media only screen and (max-width: $sm) {
      @include popout(.25em);
  }
}
/* sm and smaller */

.row-no-wrap.row {
  flex-wrap: nowrap;
}
.row-no-margin.row {
  margin-left: 0;
  margin-right: 0;
}

.visible_up_next {
  @include rounded-corners;
}


.collapse-col button {
  border-radius: 0 !important;
  position: relative;
}

//$offset: 50rem;
div.sticky, .sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}

#queue_wrapper {
  background-color: $primary-container;
  @include dark-border(1px, "", black);
  @include popout(0);
  margin-bottom: 2rem;
}

#queue_wrapper.sticky {
  @include rounded-corners(.25);
  margin-bottom: 0;
}
#queue_wrapper {
  z-index: 100;
  padding: .5rem;
  width: auto;
  overflow-x: hidden;
  border-radius: 0;
}


.top_level_card.roundup_card.card {
  & > .card-header {
    top: 0;
    z-index: 99;
  }
}
.queue_col .card-header button{
  order: 0;
  margin-right: 1rem;
}
.queue_col .card-header b{
  order: 1
}

/*Mobile screen*/
.col.queue_col {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: $md) {

  .centerDiv.react-player {
    height: unset !important;
    margin: 0;
    padding: 0;
  }

}

#sticky_collapse_row {
  background: $primary-container;
  .reddit_link_and_info {
    align-content: center;
  }
}

.visible_up_next#queue_up_next {
  @include dark-border(1px);
  background: $secondary;
  margin-left: 0;
  margin-right: 0;
}
.ui.popup:before {
  background: $tertiary-container!important;
}
.ui.visible.popup {
  //background: $secondary-container;
  background: $tertiary-container;
  border: 1px solid black;
}
.btn.btn-primary.queue_item_button{
  width: 100%;
  @include button($secondary-container, $secondary-container, $text-color: $on-secondary-container, $include-focus: false);
  border: none;
  border-bottom: 1px solid black;

  color: $on-secondary-container;

  border-radius: 0;
  &:hover {
    color: $on-secondary-container!important;
    border-bottom: 1px solid black!important;
  }
  &:focus, &:active  {
    color: $on-secondary-container!important;
  }
  @media (max-width: $md) {
    //width: max-content;
  }
}
.btn.btn-primary.queue_item_button.current_queue_item {
  border: none !important;
  cursor: default;
  @include depressed();

  &:hover,
  &:focus:active,
  &:focus,
  &:active {
    @include depressed();
  }
}

#no_reddit_acct_message {
  @include rounded-corners;
  width: max-content;
  padding: 3px;
}


// ~~~ END QUEUE ~~~
