:root {
  --md-source: #50982D;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #072100;
  --md-ref-palette-primary20: #113800;
  --md-ref-palette-primary25: #164500;
  --md-ref-palette-primary30: #1c5200;
  --md-ref-palette-primary35: #225f00;
  --md-ref-palette-primary40: #286c00;
  --md-ref-palette-primary50: #40871d;
  --md-ref-palette-primary60: #59a236;
  --md-ref-palette-primary70: #73bd4e;
  --md-ref-palette-primary80: #8dda66;
  --md-ref-palette-primary90: #a8f77f;
  --md-ref-palette-primary95: #cdffaf;
  --md-ref-palette-primary98: #eeffde;
  --md-ref-palette-primary99: #f7ffec;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #131f0d;
  --md-ref-palette-secondary20: #283420;
  --md-ref-palette-secondary25: #333f2b;
  --md-ref-palette-secondary30: #3e4a35;
  --md-ref-palette-secondary35: #495640;
  --md-ref-palette-secondary40: #55624c;
  --md-ref-palette-secondary50: #6e7b63;
  --md-ref-palette-secondary60: #87957c;
  --md-ref-palette-secondary70: #a1b095;
  --md-ref-palette-secondary80: #bdcbb0;
  --md-ref-palette-secondary90: #d9e7cb;
  --md-ref-palette-secondary95: #e7f6d9;
  --md-ref-palette-secondary98: #effee1;
  --md-ref-palette-secondary99: #f7ffec;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #002020;
  --md-ref-palette-tertiary20: #003738;
  --md-ref-palette-tertiary25: #0f4243;
  --md-ref-palette-tertiary30: #1e4e4e;
  --md-ref-palette-tertiary35: #2b5a5a;
  --md-ref-palette-tertiary40: #386666;
  --md-ref-palette-tertiary50: #517f7f;
  --md-ref-palette-tertiary60: #6b9999;
  --md-ref-palette-tertiary70: #85b4b4;
  --md-ref-palette-tertiary80: #a0cfd0;
  --md-ref-palette-tertiary90: #bbebec;
  --md-ref-palette-tertiary95: #c9fafa;
  --md-ref-palette-tertiary98: #e2ffff;
  --md-ref-palette-tertiary99: #f1ffff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1a1c18;
  --md-ref-palette-neutral20: #2f312d;
  --md-ref-palette-neutral25: #3a3c37;
  --md-ref-palette-neutral30: #464742;
  --md-ref-palette-neutral35: #51534e;
  --md-ref-palette-neutral40: #5d5f5a;
  --md-ref-palette-neutral50: #767872;
  --md-ref-palette-neutral60: #90918b;
  --md-ref-palette-neutral70: #abaca5;
  --md-ref-palette-neutral80: #c6c7c0;
  --md-ref-palette-neutral90: #e3e3dc;
  --md-ref-palette-neutral95: #f1f1ea;
  --md-ref-palette-neutral98: #fafaf3;
  --md-ref-palette-neutral99: #fdfdf6;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #181d15;
  --md-ref-palette-neutral-variant20: #2d3229;
  --md-ref-palette-neutral-variant25: #383d33;
  --md-ref-palette-neutral-variant30: #43483e;
  --md-ref-palette-neutral-variant35: #4f544a;
  --md-ref-palette-neutral-variant40: #5b6055;
  --md-ref-palette-neutral-variant50: #74796d;
  --md-ref-palette-neutral-variant60: #8d9286;
  --md-ref-palette-neutral-variant70: #a8ada0;
  --md-ref-palette-neutral-variant80: #c3c8bb;
  --md-ref-palette-neutral-variant90: #e0e4d7;
  --md-ref-palette-neutral-variant95: #eef3e5;
  --md-ref-palette-neutral-variant98: #f7fbed;
  --md-ref-palette-neutral-variant99: #f9fef0;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #286c00;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #a8f77f;
  --md-sys-color-on-primary-container-light: #072100;

  --md-sys-color-secondary-light: #55624c;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #d9e7cb;
  --md-sys-color-on-secondary-container-light: #131f0d;

  --md-sys-color-tertiary-light: #386666;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #bbebec;
  --md-sys-color-on-tertiary-container-light: #002020;

  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;

  --md-sys-color-background-light: #fdfdf6;
  --md-sys-color-on-background-light: #1a1c18;

  --md-sys-color-surface-light: #fdfdf6;
  --md-sys-color-on-surface-light: #1a1c18;
  --md-sys-color-surface-variant-light: #e0e4d7;
  --md-sys-color-on-surface-variant-light: #43483e;

  --md-sys-color-outline-light: #74796d;
  --md-sys-color-inverse-on-surface-light: #f1f1ea;
  --md-sys-color-inverse-surface-light: #2f312d;
  --md-sys-color-inverse-primary-light: #8dda66;

  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #286c00;
  --md-sys-color-outline-variant-light: #c3c8bb;
  --md-sys-color-scrim-light: #000000;
  /* dark */
  --md-sys-color-primary-dark: #8dda66;
  --md-sys-color-on-primary-dark: #113800;
  --md-sys-color-primary-container-dark: #1c5200;
  --md-sys-color-on-primary-container-dark: #a8f77f;
  --md-sys-color-secondary-dark: #bdcbb0;
  --md-sys-color-on-secondary-dark: #283420;
  --md-sys-color-secondary-container-dark: #3e4a35;
  --md-sys-color-on-secondary-container-dark: #d9e7cb;
  --md-sys-color-tertiary-dark: #a0cfd0;
  --md-sys-color-on-tertiary-dark: #003738;
  --md-sys-color-tertiary-container-dark: #1e4e4e;
  --md-sys-color-on-tertiary-container-dark: #bbebec;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1a1c18;
  --md-sys-color-on-background-dark: #e3e3dc;
  --md-sys-color-surface-dark: #1a1c18;
  --md-sys-color-on-surface-dark: #e3e3dc;
  --md-sys-color-surface-variant-dark: #43483e;
  --md-sys-color-on-surface-variant-dark: #c3c8bb;
  --md-sys-color-outline-dark: #8d9286;
  --md-sys-color-inverse-on-surface-dark: #1a1c18;
  --md-sys-color-inverse-surface-dark: #e3e3dc;
  --md-sys-color-inverse-primary-dark: #286c00;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #8dda66;
  --md-sys-color-outline-variant-dark: #43483e;
  --md-sys-color-scrim-dark: #000000;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
