@import "loader.css";
@import "color-scheme";

.privacy {
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }

  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }

  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }

  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }

  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }

  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }

  ul {
    list-style-type: square;
  }

  ul > li > ul {
    list-style-type: circle;
  }

  ul > li > ul > li > ul {
    list-style-type: square;
  }

  ol li {
    font-family: Arial;
  }
}
.no_roundup_alert .h4.alert-heading {
  margin-bottom: 0 !important;
}
.roundup_header {
  row-gap: .3rem;
  margin-bottom: .2rem;
  justify-content: space-between;
  @media (min-width: $sm) {
    justify-content: flex-start;
  }
}
.alert.alert-warning {
  background-color: #ffd24d!important;
  color: $font-color!important;
}

.profilePage .form-control {
  width: 50%;
}

.Toastify__toast-container--top-right {
  top: 4em;
}
.debug_info {
  overflow-y: scroll;
  color: black;
  max-width: 25%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
}


*, *:before, *:after {
  box-sizing: inherit;
}

.hidden {
  display: none !important;
}

.centerBody {
  margin: auto;
  overflow: visible;
  width: 100%;
}

.reddit_post_data .mirror_row > div {
  margin-top: .2rem;
}

.form-group .form-group {
  padding: 1em;
}

.form-group {
  width: initial;
  padding: 2em 2em 2em 2em;
  @include rounded-corners;

  margin: .25em !important;
}

.navBarWrapper {
  padding: .5em;
  display: inline-flex;
  @include rounded-corners;
}

.navbar-collapse.collapse.show {
  max-width: 20em;
  padding: 1em;
  float: right;
}

.navbar-nav {
  font-weight: 800;
  padding: 1em;
  @include rounded-corners;
}


.nav-link:not(.nav-separator):hover {
  @include rounded-corners;
}

.no_wrap {
  text-wrap: nowrap;
}
.nested_card_flex.card {
  display: inline-flex !important;
  width: inherit;
}
.nested_card.list-group-item {
  border: none;
  padding: 0;
}

.nested_card + .nested_card {
  margin-top: 1em;
}
.nested_card.config_card {
  width: 45%;
}
.config_card {
  margin-right: 1em;
}
.config_card_header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn.expand_or_enqueue_button {
  //height: 2.5em;
  //width: 2.5em;
  margin-top: .25em;
  margin-right: .25em;
}

.dequeue_button {
  padding: 0 !important;

  height: 100%;
  max-height: 2rem;
  width: 2em;
}

.reddit_post {
  width: 95%;
  display: inline-block;
  margin: .5em;
  position: relative;
}


.reddit_post.container {
  border-radius: 1em;
  //padding: 1em 1em 1em 2em;
  padding: .5rem 0;
  position: relative;
  max-width: unset;
  @media only screen and (max-width: $sm) {
    padding: 0 .5rem
  }

}

@media only screen and (max-width: $md) {
  .reddit_post.container > .row {
    margin-left: 0;
  }
}

.thumbnailColumn{
  flex: 0 0 0 !important;
}
.thumbnailColumn.col-3 {
  padding: 0;
}

.reddit_post_thumbnail {
  display: flex;
}

.top_level_card {
  margin: 1em
}

#listRoundupsContainer {
  min-width: 50em;
}

.mark_viewed_col {
  align-content: center;
}
.roundupListItem {
  margin-bottom: 1em;
}

.btn.btn-primary.roundupViewButton {
  margin-right: .4em;
  text-wrap: auto;
  white-space: wrap;
}

.welcomeCard {
  margin: auto;
  text-align: center;
  width: auto;
}

.playIcon svg {
  width: 25px;
}

.modal-title {
  margin-left: .5em;
  margin-top: .5em;
}

.video-react {
  height: auto;
  margin: auto;
  max-height: 337px;
  max-width: 600px;
  width: 100% !important;
}

.max-width-7 {
  max-width: 7rem;
}

.max-width-10 {
  max-width: 10rem;
}

.max-width-30 {
  max-width: 30rem;
}

.centerDiv {
  margin: auto !important;
  width: 100% !important;
}

.centerDiv-fifty, .form-group.centerDiv-fifty {
  margin: auto !important;
  width: 50% !important;
}

.centerDivNoPad {
  margin: auto !important;
  width: 100% !important;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 1em auto;
  max-width: 75em;
  -moz-osx-font-smoothing: grayscale;
}

.routerBody {
  justify-content: center;
  margin: 1em 0;
  width: 100%;
}


.card-header {
  @include rounded-corners;
  @include popout;

  //display: flex;
  //justify-content: center;
  //align-items: flex-end;
  h4 {
    font-weight: 700;
    font-size: 24px;
  }
}
.alignContentCenter {
  align-content: center;
}
.alignTextStart {
  text-align: start;
}

.bigBoldText {
  font-weight: bold;
  font-size: 1.2rem;
}
.boldText {
  font-weight: bold;
}

.roundup_matcher .card-header {
  flex-wrap: wrap;
}
.roundup_matcher .item {
  min-height: 7em;
}

.reddit_post p {
  margin-bottom: 0;
}

.reddit_self_text a{
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
.reddit_self_text table {
  margin-bottom: 1em;
}

.underline {
  text-decoration: underline;
}

.customThumbnail {
  @include rounded-corners;

  // height and width should mirror the logic in RedditPost.js:
  // displayWidth =
  //      screenWidth <= 420 ? 75 :
  //          breakpoint.val <= SM.val ? 100 :
  //              150;
  height: 150px;
  width: 150px;
  @media only screen and (max-width: $md) {
    height: 100px;
    width: 100px;
  }
  @media only screen and (max-width: 420px) {
    height: 75px;
    width: 75px;
  }
}

.customThumbnail svg {
  height: 2em;
  width: 2em;
  margin: auto;
}

.roundupListItem + .roundupListItem {
  padding-top: 1em;
}

.up_arrow {
  height: 2em;
  width: 2em;
}

.responsive_iframe_container {
  overflow: hidden;
  position: relative;
  width: 100%;

  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  //padding-top: 56.25%;
  padding-top: 45%;
  @media only screen and (max-width: $md) {
    padding-top: 55%;
  }
}
.responsive_iframe_container.iframe_bandcamp_album{
  padding-top: 30%
}
.responsive_iframe_container.iframe_bandcamp_track {
  padding-top: 15%;
}

.responsive_iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.no_scroll {
  overflow: hidden;
}
.responsive_image {
  height: auto;
  width: 100%;
}

.expanded_image {
  display: block;
  padding-top: 1em;
  position: relative;
}

.reddit_self_text blockquote {
  border-left: 2px solid gray;

  /* adds margin to the top and bottom of the block*/
  margin-block-end: 1em;
  margin-block-start: 1em;

  /* adds margin to the left and right of the text*/
  margin-inline-end: 1em;
  margin-inline-start: 3em;

  padding-left: 1em;
}


/* begin edit config styles */
.subNameAndSaveButton {
  margin: 0 !important;
  max-width: none !important;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: sticky;
  top: 0;
  z-index: 99;
  @include rounded-corners;;
  @include dark-border(2px);
  background: $secondary-container;
}


.subredditConfig.container-fluid,
.subredditConfig.container {
  @include rounded-corners;;
}

.subredditConfig.container-fluid,
.subredditConfig.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 1em;
}

.subredditConfig .form-label {
  font-weight: bold;
  overflow: auto;
}

.subredditConfig #displayName {
  margin-left: 0;
}


.filter-level-0.list-group-item{
  padding-left: 0;
}
.roundupFilter + .roundupFilter {
  padding-top: .25em;
}

.filterHeading {
  padding: 0 !important;
}

.filter-level-5 {
  border-left: 5px solid indigo !important;
}

.filter-level-4 {
  border-left: 5px solid olive !important;
}

.filter-level-3 {
  border-left: 5px solid violet !important;
}

.filter-level-2 {
  border-left: 5px solid maroon !important;
}

.filter-level-1 {
  border-left: 5px solid blue !important;
}

/*end edit config styles*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.spacer {
  padding: 70px 0;
}

#root {
  display: flex;
}

.and-filter, .or-filter {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-menu.show {
  top: -1px !important;
  left: -1px !important;
}

.matcher_title {
  font-weight: bold;
  text-transform: capitalize;
}


.reddit_text_span {
  position: relative;
  text-wrap: nowrap;
  //text-decoration: underline;
  //text-decoration-thickness: .3rem;
}

.reddit_comment_icons {
  width: 17px;
  height: 17px;
  display: inline-block;

  bottom: 2px;
  position: relative;
}

.btn.responsive_button {
  padding: .375rem 0.5rem;
}

.combo-filter .dropdown {
  margin-right: 1rem !important;
}
/* sm and smaller */
@media only screen and (max-width: $sm) {
  /* Hide the nav separators if the nav is collapsed (ie if the hamburger menu is displayed) */
  .nav-separator {
    display: none !important;
  }
}

/* TODO re-arrange the css. All rules should be for mobile by default.
     Then set breakpoints for bigger screens */

/*Small screen*/
@media only screen and (max-width: $md) {
  .centerDiv {
    padding: .25em !important;
    width: unset;
  }

  .subredditConfig.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: .25em;
    padding-left: .25em;
  }

  .centerDivNoPad {
    width: unset;
  }

  .top_level_card + .top_level_card {
    margin-top: .75em;
  }

  .top_level_card {
    margin: .25em;
  }

  .top_level_card.roundup_card.card {
    border-radius: 0;
    border: none !important;

    .card-body {
      padding: 0 !important;
      border-radius: 0;
    }

    .card,
    .card-header:first-child,
    .card-header {
      //position: sticky;
      border-radius: 0 !important;
    }
  }


  .form-group .form-group {
    padding: .25em;
  }

  .form-group {
    padding: .5em;
    @include rounded-corners;
    margin: .25em !important;
  }

  .roundupFilter.list-group-item {
    padding: .25em;
  }

  .editableFilter.list-group-item,
  .roundupSectionListItem {
    padding: .25em .5em;
  }

  .modal-open {
    /*Position and width*/
    bottom: 0;
    height: fit-content;
    left: 0;

    margin: auto;
    position: fixed;
    right: 0;
    top: 0;

    /*Centering horizontally*/
    width: 30%;
  }

  .min-width-35 {
    min-width: 35rem;
  }

  .min-width-70 {
    min-width: 70rem;
  }
  .subredditConfig {
    padding: .25em;
  }
}

// sm and larger
@media only screen and (min-width: $sm) {

  .up_arrow {
    height: 2em;
    width: 2em;
  }
  .btn.responsive_button {
    padding: .375rem 0.75rem;
  }

  #root {
    display: flex;
  }

}

//COLORS BELOW vvvv

.btn.btn-primary {
  @include button(var(--md-ref-palette-primary70), $primary, $text-color: $on-primary);
}
.btn.btn-outline-primary {
  @include outline-button(var(--md-ref-palette-primary70), $primary, $text-color: $on-primary);
}

.btn.btn-secondary {
  //@include button($tertiary, $tertiary);
  @include button(var(--md-ref-palette-tertiary70), $tertiary, $text-color: $on-tertiary);
  //@include button(#3bb292, #007355);
}
.btn.btn-outline-secondary{
  @include outline-button(var(--md-ref-palette-tertiary70), $tertiary, $text-color: $on-tertiary);
}

.btn.btn-danger {
  @include button(var(--md-ref-palette-error70), $error)
}
.btn.btn-outline-danger {
  @include outline-button(var(--md-ref-palette-error70), $error)
}

// ~~~ END BUTTONS ~~~
.progress-bar {
  color: black !important;
}

.customSpotifyContainer {
  background: $primary-container;
}

.centerBody {
  background-color: $secondary-container;

}

a:hover {
  color: #0D362BFF !important;
}

.nav-link, a:not(.reg-link) {
  color: #374549 !important;
}

.highlight-negate {
  background-color: yellow;
  padding: .25em;
}

.welcomeCard {
  border: 3px solid black;
}

.Toastify__toast {
  color: black;
}

// ~~ Text boxes and dropdowns ~~

input, select {
  @include dark-border(1px);
}

.ui.multiple.search.dropdown {
  @include dark-border(2px)
}


// ~~ END Text boxes and dropdowns ~~

.subredditConfig {
  background: $secondary-container;
}


.roundup_matcher .item + .item {
  border-top: 1px solid $primary;
}

.roundupSectionListItem {
  margin-bottom: 1em;
  @include dark-border(2px);
  background: $secondary-container;
}

.form-group {
  @include dark-border(1px);
  background: $secondary-container;
}
.invisible-form-group {
  background: $primary-container;
  border: none !important;
}


.navBarWrapper {
  background: $secondary-container;
}

.nav-link:not(.nav-separator):hover {
  background-color: $primary-container;
  color: $on-primary-container
}

.mirror_row.row{

  flex-wrap: nowrap;
  overflow-x: auto;

  margin-left: 0;
  margin-right: 0;
  margin-top: .2em;
  background: $secondary-container;

  & > div {
    padding: .3em;
    background: var(--md-ref-palette-secondary70);
    //background: $secondary-container;
  }
  & button {
    border: 1px solid black !important;
  }
  & div:first-child {
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
  }
  & div:last-child {
    border-top-right-radius: .5em;
    border-bottom-right-radius: .5em;
  }
}

#queue_wrapper .mirror_row.row {
  width: fit-content;
  border: 1px solid $on-primary-container;
  @include popout(0);
  & > div {
    background: $secondary-container;
  }
  @media only screen and (min-width: $md) {
    float: right;
  }
}
// ~~~ END NAVBAR ~~~

.list .queue_item.item {
  padding: .5rem;
  background-color: transparent !important;
}

@media (max-width: $md) {
  #roundup_container > .card-body {
    padding: .2rem;
  }
  #roundup_container .meta_col {
    padding: 0 .2rem;
  }
}


// ~~~ CARDS ~~~


.top_level_card.card {
  border: 2px solid black !important;
}

.card {
  //background-color: $primary-container !important;
  @include dark-border(2px);
}

.card-body {
  background-color: $secondary-container !important;
}

.card-header {
  background-color: $secondary-container !important;
  //@include dark-border(1px, "-bottom");
}
@media (max-width: $lg) {
  .roundup_card > .card-header {
    @include dark-border(1px);
  }
}
.roundup_card > .card-header {
  //border: 0!important;
}

// ~~~ END CARDS ~~~

// ~~~ ROUNDUPS ~~~

//.roundup_next, .roundup_prev {
//  border-left: 1px solid black;
//}

.roundupFilter.list-group-item {
  background-color: $secondary-container !important;
  border: none;
}

.uneditableSection {
  @include popout;
}

.editableSection {
  @include dark-border(3px);
  background-color: $primary-container !important;
}

.editableSection.list-group-item {
  background: $primary-container;
}

.reddit_self_text table {
  border: 2px solid black;
}

.customThumbnail {
  background-color: $primary;
  @include popout;
  box-shadow: 0 0 14px 1px rgba(000, 000, 000, 0.5),
  inset 2px 2px 0 rgba(255, 255, 255, 0.3);

}

.customThumbnail svg {
  color: white;
}

.roundupListItem + .roundupListItem {
  @include dark-border(1px, "-top");
}

.up_arrow {
  color: white;
}

.reddit_title_link .title {
  line-height: 140%;
  font-size: 1.3rem;
}
.reddit_link_and_info a {
  font-weight: 700;
  display: block;
  width: max-content;
}
.reddit_link_and_info a:visited {
  font-weight: 900;
}
.reddit_link_and_info a:hover,
.reddit_link_and_info:hover {
  background: #952e00;
  text-decoration: none;
}

.reddit_post_flair {
  margin-right: 3px;
  padding: 3px;
}

.reddit_self_text td, .reddit_self_text th {
  border: 1px solid black;
  padding: .5em;
}
.reddit_self_text blockquote p{
  margin-left: .5rem;
}
.reddit_self_text blockquote {
  border-left: 2px solid #c5c1ad;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 4rem;
  margin-inline-end: 4rem;
  unicode-bidi: isolate;
}
.reddit_self_text p {
  line-height: 24px;
}
.no-border {
  border: none !important;
}
#reddit_connect_button {
  background-color: orangered !important;
  background: none;
  border: none;
}
.reddit_link_and_info {
  padding: .2rem;
  @include rounded-corners;
  width: fit-content;
  background: orangered;
}
.roundup_card .reddit_link_and_info {
  padding-top: .4rem;
}
.reddit_link_and_info a {
  color: white !important;
}



.reddit_post.container {
  background-color: $secondary-container;
}

.reddit_post_flair {
  background-color: $grey-2;
  color: black;
}

// ~~~ END ROUNDUPS ~~~


.toggle-switch-inner:before {
  background-color: $primary;
  color: #fff;
}
.editConfigReddtLink {
    text-decoration: underline;
  &:hover {
    text-decoration: none!important;
  }
}
.tertiary-container{
  background: $tertiary-container;
}
.white_text {
  //margin: .1rem;
  border: 1px solid $on-primary-container;
  //border-style: inset;
  //border-style: solid;
  //border-color: black;
  //border-color: $primary;
  //background: $secondary;
  //background: $tertiary-container;
  background: $secondary-container;
  //@include rounded-corners();
}

.reddit_self_text {
  //background-color: #e6e6e6;
  background-color: $surface-variant;
  color: $on-surface-variant;
  border-radius: 1em;
  margin-top: 1em;
  padding: 1em;
}


.uppercase {
  text-transform: uppercase;
}

.roundup_card > .card-header {
  padding: .4rem!important;
}
.roundupNavButton {
  float: right;
}

.nav_list {
  max-height: min(22vw, 10rem);
  overflow-y: auto;
  overflow-x: hidden;
}
.list > .item.roundupNavRow.thisRoundupNav,
.list-group-item.roundupNavRow.thisRoundupNav {
  @include depressed(1px, 1px, 7px, 2px);
  background: $primary-container;
  border: 1px solid black;
}

.list > .item.roundupNavRow,
.list-group-item.roundupNavRow {
  padding: .5rem 0 !important;
  //height: min(6vw, 3rem);
  text-align: center;
  background: transparent;
  align-items: center;
  justify-content: flex-end;
  display: block;

  @media (max-width: 1023px) {
    padding: .33rem 0 !important;
  }
}


.roundupNavRow {
  cursor: pointer;
}
.roundupNavRow.thisRoundupNav {
  cursor: text;
}
.left_side .roundupNavRow {
  margin-left: -.3rem;
  margin-right: .25rem;
}
.right_side .roundupNavRow {
  margin-right: -.3rem;
  margin-left: .25rem;
}
@media (max-width: $sm) {
  .left_side .roundupNavRow {
    margin-right: -.5rem;
  }
  .right_side .roundupNavRow {
    margin-left: -.5rem;
  }
}
.nav_wrapper {
  border: 1px solid black;
  margin: 0!important;
  background: $secondary-container;
  color: $on-secondary-container;
  @include popout(.2rem!important);
  //@include popout(0!important);
  padding: 0 !important;
  & > .row {
    align-items: flex-end;
  }
  & > .row + .row {
    margin-top: .2rem;
  }
  .left_side, .right_side {
    text-wrap: nowrap;
    min-height: 6rem;
  }
}