$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin rounded-corners($w: .25) {
  border-radius: #{$w}rem;
}

@mixin depressed($x: 2px, $y: 2px, $blur: 5px, $spread: 4px) {
  box-shadow: inset $x $y $blur $spread rgba(0,0,0,0.5);
}

@mixin popout($padding: 1em) {
  box-shadow: 1px 1px 1px rgba(000, 000, 000, 0.5), inset 2px 2px 0 rgba(255, 255, 255, 0.3);
  padding: $padding;
  @include rounded-corners;
}

// ~~~ BUTTONS ~~~
@mixin button($light-color, $dark-color, $degrees:0, $text-color:white, $include-focus: true) {
  background: linear-gradient(
                  #{$degrees}deg,
                  $light-color,
                  $dark-color);
  color: $text-color;
  border-color: $dark-color;
  align-content: center;

  &:not([disabled]):hover {
    background: linear-gradient(#{$degrees - 130}deg, $light-color, $dark-color);
    border-color: $dark-color;
    color: white;
    filter: brightness(105%);
  }
  @if $include-focus {
    &:focus, &:active {
      background-color: $light-color !important;
      border-color: $dark-color !important;
      box-shadow: 0 0 0 0.1rem $dark-color;
    }
  }

  &:active {
    box-shadow: none;
  }
  @media (max-width: $md) {
    padding: .25rem .4rem;
    white-space: nowrap;
  }
}

@mixin outline-button($light-color, $dark-color, $degrees:0, $text-color:white, $include-focus: true) {
  background: transparent;
  color: $dark-color;
  border-color: $dark-color;

  &:not([disabled]):hover {
    background: linear-gradient(
                    #{$degrees - 130}deg,
                    $light-color,
                    $dark-color);
    border-color: $dark-color;
    color: white;
    filter: brightness(105%);
  }
  @if $include-focus {
    &:focus, &:active {
      //background-color: $light-color !important;
      border-color: $dark-color !important;
      box-shadow: 0 0 0 0.1rem $dark-color;
    }
  }

  &:active {
    box-shadow: none;
  }
}

:root {
  --padding: 1em;
  --margin: 1em;
}
